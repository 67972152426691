import React from "react"
import CategoryWrapper from "../../components/CategoryWrapper"

function MenUnderwearPage() {
  return (
    <CategoryWrapper
      seoTitle="Undertøj"
      gender="herre"
      category="underwear"
    />
  )
}

export default MenUnderwearPage
